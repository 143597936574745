// **  Initial State
const initialState = {
  userData: {},
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      state.userData = action.data
      return {
        ...state,
      }
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    default:
      const userData = JSON.parse(localStorage.getItem('userData'))
      return { userData }
  }
}

export default authReducer
